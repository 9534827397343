import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory, BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
// styles
import useStyles from './styles';

// components
import mock from './mock';
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import useQueryParams from '../../hooks/useQueryParams';

import documentApi from '../../services/document.service';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import { useUserState } from '../../context/UserContext';
import FileViewerButton2 from '../../components/FileViewerButton2';
import { defaultDocumentGroups } from '../../constants/documentGroups';
import fileUploader from '../../utility/fileuploader';
import CustomMenu from '../../components/CustomMenu';
import CustomFileDropzone from '../../components/CustomFileDropzone';

const getFileNameFromURL = (value) => {
  let result = value;

  const urlKey = `amazonaws.com/`;
  if (value.indexOf(urlKey) >= 0) {
    let urlArray = value.split(urlKey);
    if (urlArray?.[1]?.length > 36) {
      result = urlArray[1].substring(36);
      result = decodeURI(result);
    }
  }

  return result;
};

export default function Companydocuments(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  let query = useQueryParams();
  const companyId = query.get('company');

  const userState = useUserState();
  const { userId: authUserId } = userState;
  let userId = parseInt(authUserId);

  // local
  const [isLoading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const selectDocRef = useRef();
  const defaultUploadForm = {
    show: false,
    data: null,
  };
  const [uploadForm, setUploadForm] = useState(defaultUploadForm);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState();

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const respDocumentslist = await documentApi.getDocumentsListSTS(2, companyId);
      let newDocuments = respDocumentslist?.data?.document || [];
      newDocuments.sort((a, b) => {
        return b.document_id - a.document_id;
      });

      setDocuments(newDocuments);
    } catch (error) {
      enqueueSnackbar(`Failed to load document(s), please try again.`, {
        variant: 'error',
      });
      setDocuments([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleSelectDocuments = async (e) => {
    console.log('e?.target?.files', e);
    if (e?.target?.files?.length > 0) {
      let newFiles = Array.from(e?.target?.files);

      newFiles = newFiles.map((anItem) => {
        return {
          file: anItem,
          name: anItem?.name,
          size: anItem?.size,
          isNew: true,
        };
        return {
          file: anItem,
          isNew: true,
        };
      });

      let newValue = [...newFiles];
      newValue = newValue.map((anItem, index) => {
        return {
          ...anItem,
          rowId: index,
        };
      });
      setSelectedDocuments(newValue);
      if (selectDocRef?.current?.value) {
        selectDocRef.current.value = '';
      }
    } else {
      setSelectedDocuments([]);
    }
  };

  const handleUploadDocuments = async () => {
    console.log('selectedDocuments', {
      selectedDocuments,
      selectedDocumentType,
    });
    try {
      setLoading(true);
      let failedUploads = [];

      for (let i = 0; i < selectedDocuments.length; i++) {
        try {
          let res = await fileUploader(
            { current: { files: [selectedDocuments[i]?.file] } },
            selectedDocumentType,
            2,
            companyId
          );
          if (res?.error) {
            failedUploads.push(res);
          }
        } catch (error) {
          // setLoading(false);
        }
      }
      if (failedUploads?.length > 0) {
        window.alert(
          `Error: Failed to Upload: \n${failedUploads.map(
            (anItem) => `${anItem?.data?.name || 'Untitled'} (${anItem?.message})\n`
          )}`
        );
      }
      fetchDocuments();
      setUploadForm(defaultUploadForm);
      setSelectedDocuments([]);
      setSelectedDocumentType();
      if (selectDocRef?.current?.value) {
        selectDocRef.current.value = '';
      }
      enqueueSnackbar(`Document(s) uploaded.`, {
        variant: 'success',
      });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar(`Failed to upload document, please try again.`, {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const handleRemoveDocument = async (value) => {
    if (value?.document_id && userId) {
      if (window.confirm('Confirm Remove?')) {
        try {
          setLoading(true);
          await documentApi.removeDocumentById(value?.document_id, userId);
          await fetchDocuments();
          enqueueSnackbar(`Document removed.`, {
            variant: 'success',
          });
          setLoading(false);
        } catch (error) {
          enqueueSnackbar(`Failed to remove document, please try again.`, {
            variant: 'error',
          });
          setLoading(false);
        }
      }
    }
  };

  let groupedDocumentsByDocumentType = [];
  if (documents?.length > 0) {
    documents.forEach((anItem) => {
      let foundGroupIndex = groupedDocumentsByDocumentType
        .map((aGroup) => aGroup.document_type_id)
        .indexOf(anItem?.document_type_id);
      if (foundGroupIndex >= 0) {
        groupedDocumentsByDocumentType[foundGroupIndex] = {
          ...groupedDocumentsByDocumentType[foundGroupIndex],
          children: [...(groupedDocumentsByDocumentType[foundGroupIndex]?.children || []), anItem],
        };
      } else {
        groupedDocumentsByDocumentType.push({
          document_type_id: anItem.document_type_id,
          document_type_name: anItem.document_type_name,
          children: [anItem],
        });
      }
    });
    groupedDocumentsByDocumentType.sort((a, b) => {
      return a?.document_type_name?.localeCompare(b?.document_type_name);
    });
  }

  const documentTableColumns = [
    {
      label: 'DATE',
      id: 'uploaded_date',
      style: {
        width: '150px',
      },
      render: (text) => {
        return text ? moment(text).format(`DD/MM/yyyy hh:mm:ssa`) : '-';
      },
    },
    {
      label: 'NAME',
      id: 'file_name',
      render: (text) => {
        return getFileNameFromURL(text);
      },
    },
    // {
    //   label: 'TYPE',
    //   id: 'document_type_name',
    // },
    {
      label: '',
      id: 'signed_document_url',
      style: {
        width: '100px',
      },
      render: (text, data) => {
        return (
          <FileViewerButton2 srcType="url" src={text} size="small" document={data}>
            View
          </FileViewerButton2>
        );
      },
    },
    {
      label: '',
      id: 'action',
      style: {
        width: '100px',
      },
      render: (_, data) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              handleRemoveDocument(data);
            }}
          >
            Remove
          </Button>
        );
      },
    },
  ];

  let documentTypeOptions = defaultDocumentGroups.map((anItem) => ({
    value: anItem.document_type_id,
    label: anItem.label,
  }));
  documentTypeOptions = documentTypeOptions.sort((a, b) => {
    return a?.label.localeCompare(b?.label);
  });

  let canUpload = selectedDocumentType && selectedDocuments?.length > 0;
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => history.replace(`/companydetails?company=${companyId}`)}
        // onClick={() => history.push({ pathname: "/dashboard" })}
      >
        BACK TO Company Details
      </Button>

      <PageTitle
        title="Company Documents"
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setUploadForm((prev) => {
                return {
                  ...prev,
                  show: true,
                };
              });
            }}
          >
            Upload
          </Button>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          {groupedDocumentsByDocumentType?.length > 0 ? (
            <>
              {groupedDocumentsByDocumentType.map((anItem, index) => {
                return (
                  <CustomAccordion
                    key={index}
                    title={anItem.document_type_name}
                    defaultExpanded={true}
                  >
                    <CustomTable
                      data={anItem.children}
                      columns={documentTableColumns}
                      elevation={0}
                    />
                  </CustomAccordion>
                );
              })}
            </>
          ) : (
            <div>No Document(s) found</div>
          )}
        </Grid>
      </Grid>
      <CustomModal
        show={uploadForm.show}
        onHide={() => {
          setUploadForm(defaultUploadForm);
        }}
        scrollable={false}
        title="Upload"
      >
        <Box mb={2}>
          <CustomMenu
            data={documentTypeOptions}
            label="Document Type"
            size="small"
            value={selectedDocumentType}
            onChange={(e) => {
              setSelectedDocumentType(e.target.value);
            }}
          />
        </Box>
        <Box mb={2}>
          <CustomFileDropzone
            multiple={true}
            files={selectedDocuments?.length > 0 ? selectedDocuments : []}
            setFiles={(files = [], removeFile = false) => {
              let result = [];
              let newValue = files?.length > 0 ? files : [];
              result = removeFile
                ? newValue
                : [...(selectedDocuments?.length > 0 ? selectedDocuments : []), ...newValue];
              console.log('CustomFileDropzone', {
                files,
                result,
              });
              handleSelectDocuments({ target: { files: result } });
            }}
            //  error={(manualTouch || meta.touched) && !!meta.error}
            //  helperText={meta.touched && meta.error ? meta.error : props.helperText || ''}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={!canUpload}
            onClick={handleUploadDocuments}
          >
            Upload
          </Button>
        </Box>
        {/* <Box mb={2} display="flex" justifyContent="flex-end">
          <Button
            component="label"
            variant="contained"
            size="small"
            onChange={handleSelectDocuments}
          >
            Select Document(s)
            <input ref={selectDocRef} type="file" multiple hidden />
          </Button>
        </Box>
        {selectedDocuments?.length > 0 && (
          <Box>
            <Box mb={2}>
              <Box>
                <b>Selected Documents</b>
              </Box>
              {selectedDocuments.map((anItem, index) => {
                return (
                  <Box key={index}>
                    {index + 1}. {anItem?.file?.name || '-'}
                  </Box>
                );
              })}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedDocumentType}
                onClick={handleUploadDocuments}
              >
                Upload
              </Button>
            </Box>
          </Box>
        )} */}
      </CustomModal>
      <Backdrop open={isLoading} style={{ zIndex: 1301 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

const useCustomAccordionStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CustomAccordion = (props) => {
  const { title, data = [], columns = [], children, ...restProps } = props;
  const classes = useCustomAccordionStyles();

  return (
    <Accordion {...restProps}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls="panel1a-content"
        // id="panel1a-header"
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data?.length > 0 && (
          <Grid container>
            {data.map((anItem, index) => {
              return (
                <Grid key={index} item xs={12}>
                  {columns.map((aColumn, index2) => {
                    const { id, render, className = '', style = {}, ...restColumn } = aColumn;
                    let itemValue = anItem?.[id] || null;
                    if (render) {
                      itemValue = render(itemValue, anItem, index);
                    }
                    return (
                      <Grid
                        key={index2}
                        item
                        className={`${className}`}
                        style={style}
                        {...restColumn}
                      >
                        {itemValue}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        )}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
