import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import { Route, Switch, withRouter, useHistory, Redirect } from 'react-router-dom';
// context
import { useLayoutState } from '../../context/LayoutContext';
// components
import Header from '../Header';
import Sidebar from '../Sidebar';
// styles
import useStyles from './styles';

import { nav, routes } from '../../constants/nav';
import { useUserState } from '../../context/UserContext';
import { getAdminUserMenuByUserId } from '../../services/module.service';

function Layout(props) {
  const classes = useStyles();
  const { userId } = useUserState();

  // global
  const layoutState = useLayoutState();
  const [adminUserMenus, setAdminUserMenus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      (async () => {
        setLoading(true);
        let res = await getAdminUserMenuByUserId(userId);
        let resultData = res?.data?.admin_user_menus?.length > 0 ? res?.data?.admin_user_menus : [];
        setAdminUserMenus(resultData);
        setLoading(false);
      })();
    } else {
      setAdminUserMenus([]);
    }
  }, [userId]);

  const filterMenus = (allMenus = [], allRoutes = [], adminUserMenus = []) => {
    let adminUserMenuIds = adminUserMenus.map((anItem) => anItem.menu_id);
    let result = [];
    allMenus.forEach((anItem) => {
      const { children, ...restItem } = anItem;
      let itemResult = restItem;
      let childrenResult = [];
      if (children?.length > 0) {
        children.forEach((anItem2) => {
          if (anItem2?.show) {
            childrenResult.push(anItem2);
            return;
          }
          if (anItem2.menu_id != undefined) {
            if (adminUserMenuIds.indexOf(anItem2.menu_id) >= 0) {
              childrenResult.push(anItem2);
            }
          } else {
            childrenResult.push(anItem2);
          }
        });
      } else {
        result.push(anItem);
      }
      if (childrenResult?.length > 0) {
        itemResult['children'] = childrenResult;
        result.push(itemResult);
      }
    });

    let routeResult = [];

    allRoutes.forEach((anItem) => {
      const { show, ...restItem } = anItem;
      if (show) {
        routeResult.push(restItem);
        return;
      }
      if (restItem.menu_id != undefined) {
        if (adminUserMenuIds.indexOf(restItem.menu_id) >= 0) {
          routeResult.push(restItem);
        }
      } else {
        routeResult.push(restItem);
      }
    });
    return { nav: result, routes: routeResult };
  };

  let { nav: newNav, routes: newRoutes } = filterMenus(nav, routes, adminUserMenus);

  return (
    <div className={classes.root}>
      <>
        <Header history={props?.history} />
        {loading ? null : <Sidebar nav={newNav} />}

        <div
          id="content-wrapper"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (!userId) {
                  return <Redirect to={'/login'} />;
                }
                return <Redirect to={'/company'} />;
              }}
            />
            {newRoutes.map((anItem, index) => {
              const { name, ...restItem } = anItem;
              let routeProps = {
                ...restItem,
              };
              return <Route key={index} exact {...routeProps} />;
            })}

            <Route
              path="*"
              component={() => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  {loading ? <CircularProgress /> : <div>Page Not Found</div>}
                </Box>
              )}
            />
          </Switch>
          <Box
            mt={5}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent="space-between"
          >
            {/* <div>
              <Link
                color={"primary"}
                href={"https://meyzer.com/"}
                target={"_blank"}
                className={classes.link}
              >
                Meyzer
              </Link>
              <Link
                color={"primary"}
                href={"https://meyzer.com/about"}
                target={"_blank"}
                className={classes.link}
              >
                About Us
              </Link>
              <Link
                color={"primary"}
                href={"https://meyzer.com/blog"}
                target={"_blank"}
                className={classes.link}
              >
                Blog
              </Link>
            </div>
            <div>
              <Link href={"https://www.facebook.com/meyzer"} target={"_blank"}>
                <IconButton aria-label="facebook">
                  <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://twitter.com/meyzer"} target={"_blank"}>
                <IconButton aria-label="twitter">
                  <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
              <Link href={"https://github.com/meyzer"} target={"_blank"}>
                <IconButton aria-label="github" style={{ marginRight: -12 }}>
                  <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
                </IconButton>
              </Link>
            </div> */}
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);

function PrivateRoute({ component, ...rest }) {
  const { isAuthenticated } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
