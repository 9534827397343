import axios from 'axios';

export default async function (
  filesRef,
  document_type_id,
  document_table_type_id,
  table_primary_id,
  callBack,
  document_container_id = 1
) {
  let result;
  if (filesRef.current.files[0] !== undefined) {
    let file = filesRef?.current?.files?.[0];
    let isFile = file instanceof File;
    if (isFile) {
      try {
        let fileParts = file.name.split('.');
        let fileType = fileParts[fileParts.length - 1];
        let fileName = [...fileParts].slice(0, -1).join('.');

        const respSignedURL = await getSignedURL(file);
        console.log('respSignedURL', respSignedURL);

        const respUploadFile = await uploadFile(
          respSignedURL.data.uploadInfo.uploadURL.toString(),
          file
        );
        console.log('respUploadFile', respUploadFile);

        if (respUploadFile.status === 200) {
          const docData = {
            module_id: 1,
            document_container_id: document_container_id,
            document_type_id: document_type_id, //76 NRIC, 109 Proof of Residential, 110 CDD documents
            document_table_type_id: document_table_type_id, //table: Users, 5 UserAddresses, 4 CDD
            table_primary_id: table_primary_id, //user_id, user_address_id, customer_due_diligence_id
            document_url: respSignedURL.data.uploadInfo.fileURL,
            file_type: fileType || null,
            file_name: fileName || null,
          };
          let respDocument;
          if (callBack) {
            respDocument = await callBack(docData);
          } else {
            respDocument = await createDocument(docData);
          }
          console.log('respDocument', respDocument);

          return respDocument;
        }
      } catch (error) {
        console.log('uploader error', error);
        // throw error;
        result = {
          error: true,
          message: error,
          data: file,
        };
      }
    } else {
      console.log('Invalid File');
      // throw 'Invalid File';
      result = {
        error: true,
        message: 'Invalid File',
        data: file,
      };
    }
  }
  return result;
}

const containerUUID2 = 'db046e36-2e55-11ed-ab06-02107e59e9f2';
const containerUUID3 = 'efe1bb03-2e55-11ed-ab06-02107e59e9f2';

export const productImageUploader = async (
  filesRef,
  document_type_id,
  document_table_type_id,
  table_primary_id,
  callBack
) => {
  if (filesRef.current.files[0] !== undefined) {
    try {
      let file = filesRef.current.files[0];
      let fileParts = file.name.split('.');
      let fileType = fileParts[fileParts.length - 1];
      let fileName = [...fileParts].slice(0, -1).join('.');

      let respSignedURL = await getSignedURLByContainer(file, containerUUID3);
      console.log('respSignedURL', respSignedURL);

      const respUploadFile = await uploadFile(
        respSignedURL.data.uploadInfo.uploadURL.toString(),
        file
      );
      console.log('respUploadFile', respUploadFile);

      if (respUploadFile.status === 200) {
        const docData = {
          module_id: 1,
          document_container_id: 3,
          document_type_id: document_type_id, //76 NRIC, 109 Proof of Residential, 110 CDD documents
          document_table_type_id: document_table_type_id, //table: Users, 5 UserAddresses, 4 CDD
          table_primary_id: table_primary_id, //user_id, user_address_id, customer_due_diligence_id
          document_url: respSignedURL.data.uploadInfo.fileURL,
          file_type: fileType || null,
          file_name: fileName || null,
        };
        let respDocument;
        if (callBack) {
          respDocument = await callBack(docData);
        } else {
          respDocument = await createDocument(docData);
        }
        console.log('respDocument', respDocument);

        return respDocument;
      }
    } catch (error) {
      console.log('uploader error', error);
    }
  }
};

export const emailUploader = async (
  filesRef,
  document_type_id,
  document_table_type_id,
  table_primary_id,
  callBack
) => {
  if (filesRef.current.files[0] !== undefined) {
    try {
      let file = filesRef.current.files[0];
      let fileParts = file.name.split('.');
      let fileType = fileParts[fileParts.length - 1];
      let fileName = [...fileParts].slice(0, -1).join('.');

      let respSignedURL = await getSignedURLByContainer(file, containerUUID2);
      console.log('respSignedURL', respSignedURL);

      const respUploadFile = await uploadFile(
        respSignedURL.data.uploadInfo.uploadURL.toString(),
        file
      );
      console.log('respUploadFile', respUploadFile);

      if (respUploadFile.status === 200) {
        const docData = {
          module_id: 1,
          document_container_id: 2,
          document_type_id: document_type_id, //76 NRIC, 109 Proof of Residential, 110 CDD documents
          document_table_type_id: document_table_type_id, //table: Users, 5 UserAddresses, 4 CDD
          table_primary_id: table_primary_id, //user_id, user_address_id, customer_due_diligence_id
          document_url: respSignedURL.data.uploadInfo.fileURL,
          file_type: fileType || null,
          file_name: fileName || null,
        };
        let respDocument;
        if (callBack) {
          respDocument = await callBack(docData);
        } else {
          respDocument = await createDocument(docData);
        }
        console.log('respDocument', respDocument);

        return respDocument;
      }
    } catch (error) {
      console.log('uploader error', error);
    }
  }
};

const getSignedURLByContainer = async (file, containerUUId = containerUUID3) => {
  let fileParts = file.name.split('.');
  let fileType = fileParts[fileParts.length - 1];
  let fileName = [...fileParts].slice(0, -1).join('.');

  let mimeType = 'application';
  if (fileType.indexOf('html') >= 0) {
    mimeType = 'text';
  }

  let queryParams = new URLSearchParams();
  queryParams.set('name', fileName);
  queryParams.set('content-type', file?.type || `${mimeType}/${fileType}`);

  // container 3
  queryParams.set('uuid', containerUUId);

  const header = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_DOCUMENT_URL}/signedurl-container?${queryParams.toString()}`, {
        headers: header,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getSignedURL = async (file) => {
  let fileParts = file.name.split('.');
  let fileType = fileParts[fileParts.length - 1];
  let fileName = [...fileParts].slice(0, -1).join('.');

  let mimeType = 'application';
  if (fileType.indexOf('html') >= 0) {
    mimeType = 'text';
  }

  let queryParams = new URLSearchParams();
  queryParams.set('name', fileName);
  queryParams.set('content-type', file?.type || `${mimeType}/${fileType}`);

  const header = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_DOCUMENT_URL}/signedurl?${queryParams.toString()}`, {
        headers: header,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const uploadFile = async (url, file, contentType) => {
  let fileType = file?.type;
  if (contentType) {
    fileType = contentType;
  }

  let options = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': fileType,
      // 'Content-Type': 'application/octet-stream',
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, file, options)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createDocument = async (body) => {
  const header = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  };

  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_DOCUMENT_URL}/documents/`, body, {
        headers: header,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
