import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  Divider,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomRadioGroupField from '../../components/CustomFormFields/CustomRadioGroupField';
import CustomUploadButton from '../../components/CustomFormFields/CustomUploadButton';
import FormikDummyField from '../../components/CustomFormFields/FormikDummyField';

import commonApi from '../../services/common.service';
import userApi from '../../services/user.service';
import companyApi from '../../services/company.service';
import businessApi from '../../services/business.service';
import shareApi from '../../services/share.service';
import addressApi from '../../services/address.service';
import shareholderApi from '../../services/shareholder.service';

import useCommonOptions from '../../hooks/useCommonOptions';
import fileUploader from '../../utility/fileuploader';
import { useUserState } from '../../context/UserContext';

// import SuperAdminTable from './components/Table/SuperAdminTable';

// import CompanyOfficerDialog from './components/Dialog/CompanyOfficerDialog';
import { CompanyOfficerTable, CompanyOfficerModalForm } from './components/forms/CompanyOfficer';
import { ShareholderTable, ShareholderModalForm } from './components/forms/CompanyShareholder';
import { SuperAdminTable, SuperAdminModalForm } from './components/forms/CompanySuperAdmin';
import { AuditorCompanyTable, AuditorCompanyModalForm } from './components/forms/CompanyAuditor';
import { CompanyAddressTable, CompanyAddressModalForm } from './components/forms/CompanyAddress';

import ShareCapital, {
  // validationSchema as shareCapitalValidationSchema,
  validationSchemaObj as shareCapitalValidationSchemaObj,
  ordinarySchema_notRequired,
  fieldNames as shareCapitalFieldNames,
} from './components/forms/ShareCapitalFields';
import CompanyDocumentsGroupItem from './components/forms/CompanyDocumentsGroupItem';
import { defaultDocumentGroups } from '../../constants/documentGroups';

const fields_companyDetail = {
  // Company Details
  company_client_type_id: {
    name: 'company_client_type_id',
    label: 'Company Category',
  },
  company_registration_no: {
    name: 'company_registration_no',
    label: 'Registered Number',
  },
  company_name: {
    name: 'company_name',
    label: 'Company Name',
  },
  former_name: {
    name: 'former_name',
    label: 'Former Name (if any)',
  },
  name_effective_from: {
    name: 'name_effective_from',
    label: 'wef',
  },
  incorporation_date: {
    name: 'incorporation_date',
    label: 'Incorporation Date',
  },
  company_type_id: {
    name: 'company_type_id',
    label: 'Company Type',
  },
  company_status_id: {
    name: 'company_status_id',
    label: 'Status',
  },
  status_date: {
    name: 'status_date',
    label: 'Status Date',
  },
  country_of_incorporation_id: {
    name: 'country_of_incorporation_id',
    label: 'Country of Incorporation',
  },
  fye_date: {
    name: 'fye_date',
    label: 'Next Financial Year End Date',
  },
  first_fye_date: {
    name: 'first_fye_date',
    label: 'First Financial Year End Date',
  },
  // country_of_operation_id: country_of_incorporation_id,
};

const fields_principalActivities = {
  // Principal Activities
  primary_ssic_id: {
    name: 'primary_ssic_id',
    label: 'Activity 1',
  },
  business_description: {
    name: 'business_description',
    label: 'Activity 1 Description',
  },
  secondary_ssic_id: {
    name: 'secondary_ssic_id',
    label: 'Activity 2',
  },
  secondary_business_description: {
    name: 'secondary_business_description',
    label: 'Activity 2 Description',
  },
};

// Capital
const fields_treasuryShares = {
  // Treasury Shares
  number_of_shares: {
    name: 'number_of_shares',
    label: 'Issued Number of Shares',
  },
};

const fields_registeredAddress = {
  // Registered Address
  address_type_id: {
    name: 'address_type_id',
    label: 'Address Type',
  },
  floor_unit_no: {
    name: 'floor_unit_no',
    label: 'Floor No. / Unit No.',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Street / Road',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Address Line 2',
  },
  postal_code: {
    name: 'postal_code',
    label: 'Postal Code',
  },
  country_id: {
    name: 'country_id',
    label: 'Country',
  },
  // address_line_3: null,
  // is_default: 1,
  // is_applying: 0,
  // is_registered: 1,
  // registration_datetime: "2021-03-31 00:00:00",
};
export const fields = {
  ...fields_companyDetail,
  ...fields_principalActivities,
  ...fields_treasuryShares,
  // ...fields_registeredAddress,
};

const client_company_id = '1';
const client_shareholder_company_id = '2';
const client_auditor_company_id = '3';

export const validationSchemaObj = {
  [fields.company_client_type_id.name]: Yup.string()
    .required(`${fields.company_client_type_id.label} is required`)
    .default(client_company_id),
  // Company Details
  [fields.company_registration_no.name]: Yup.string()
    .required(`${fields.company_registration_no.label} is required`)
    .default(''),
  [fields.company_name.name]: Yup.string()
    .required(`${fields.company_name.label} is required`)
    .default(''),
  [fields.former_name.name]: Yup.string().default(''),
  [fields.name_effective_from.name]: Yup.string(),
  [fields.incorporation_date.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.incorporation_date.label} is required`),
    })
    .default(''),
  [fields.company_type_id.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.company_type_id.label} is required`),
    })
    .default(''),
  [fields.company_status_id.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.company_status_id.label} is required`),
    })
    .default(''),
  [fields.status_date.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.status_date.label} is required`),
    })
    .default(''),
  [fields.country_of_incorporation_id.name]: Yup.string()
    .required(`${fields.country_of_incorporation_id.label} is required`)
    .default(''),
  [fields.first_fye_date.name]: Yup.string().when(fields.fye_date.name, {
    is: (value) => {
      let result = value == undefined || value == null || value == '';
      console.log('result', { result, value });
      return result;
    },
    then: Yup.string().required(`${fields.first_fye_date.label} is required`).default(''),
    // then: Yup.string(),
    otherwise: Yup.string(),
  }),
  [fields.fye_date.name]: Yup.string().when(fields.first_fye_date.name, {
    is: (value) => value == undefined || value == null || value == '',
    then: Yup.string().required(`${fields.fye_date.label} is required`).default(''),
    // then: Yup.string(),
    otherwise: Yup.string(),
  }),

  // Principal Activities
  [fields.primary_ssic_id.name]: Yup.string().when(fields.company_client_type_id.name, {
    is: client_company_id,
    then: Yup.string().required(`${fields.primary_ssic_id.label} is required`),
  }),
  [fields.business_description.name]: Yup.string(),
  [fields.secondary_ssic_id.name]: Yup.string(),
  [fields.secondary_business_description.name]: Yup.string(),
  // Capital
  // Treasury Shares
  [fields.number_of_shares.name]: Yup.string(),
  // Registered Address
  // [fields.address_type_id.name]: Yup.string().required(
  //   `${fields.address_type_id.label} is required`
  // ),
  // [fields.floor_unit_no.name]: Yup.string(),
  // [fields.address_line_1.name]: Yup.string().required(`${fields.address_line_1.label} is required`),
  // [fields.address_line_2.name]: Yup.string(),
  // [fields.postal_code.name]: Yup.string().required(`${fields.postal_code.label} is required`),
  // [fields.country_id.name]: Yup.string().required(`${fields.country_id.label} is required`),

  // Upload Documents
  // Company Officers
  // Shareholders
  // Super Admins
};

function isRequired(fieldName, schema) {
  return schema?.fields?.[fieldName]?.exclusiveTests?.required || false;
}

const defaultCommonData = {
  companyCategories: [
    {
      label: 'Client Company',
      value: client_company_id,
    },
    {
      label: "Client's Shareholder Company",
      value: client_shareholder_company_id,
    },
    {
      label: 'Auditor Company',
      value: client_shareholder_company_id,
    },
  ],
  companyTypes: [],
  companyStatuses: [],
  countries: [],
  ssic: [],
  addressTypes: [],
};

const companyCategoryOptions = [
  {
    label: 'Client Company',
    value: client_company_id,
  },
  {
    label: "Client's Shareholder Company",
    value: client_shareholder_company_id,
  },
  {
    label: "Client's Auditor Company",
    value: client_auditor_company_id,
  },
];

const CompanyForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userId: authUserId } = useUserState();
  let history = useHistory();

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [dynamicRequire, setIsDynamicRequired] = useState(true);
  const [dynamicRequireCompanyType, setIsDynamicRequiredCompanyType] = useState(true);

  // const validationSchema = useMemo(() => {
  //   if (dynamicRequire && dynamicRequireCompanyType) {
  //     return Yup.object().shape(
  //       {
  //         ...validationSchemaObj,
  //         ...shareCapitalValidationSchemaObj,
  //       },
  //       [[fields.first_fye_date.name, fields.fye_date.name]]
  //     );
  //   }
  //   return Yup.object().shape({
  //     ...validationSchemaObj,
  //     ...shareCapitalValidationSchemaObj,
  //     ...ordinarySchema_notRequired,
  //     [fields.first_fye_date.name]: Yup.string().default(''),
  //     [fields.fye_date.name]: Yup.string().default(''),
  //   });
  // }, [dynamicRequire, dynamicRequireCompanyType]);

  let validationSchema = Yup.object().shape({
    ...validationSchemaObj,
    ...shareCapitalValidationSchemaObj,
    ...ordinarySchema_notRequired,
    [fields.first_fye_date.name]: Yup.string().default(''),
    [fields.fye_date.name]: Yup.string().default(''),
  });
  if (dynamicRequire && dynamicRequireCompanyType) {
    validationSchema = Yup.object().shape(
      {
        ...validationSchemaObj,
        ...shareCapitalValidationSchemaObj,
      },
      [[fields.first_fye_date.name, fields.fye_date.name]]
    );
  }

  const changeDynamicRequired = useCallback(
    (value, formik) => {
      let isShareholderCompany =
        value == client_shareholder_company_id || value == client_auditor_company_id;
      let dynamicRequire = isShareholderCompany ? false : true;
      setIsDynamicRequired(dynamicRequire);
      if (isShareholderCompany) {
        if (!formik.values[fields.company_type_id.name]) {
          formik.setFieldValue(fields.company_type_id.name, 13);
        }
        if (!formik.values[fields.company_status_id.name]) {
          formik.setFieldValue(fields.company_status_id.name, 68);
        }
      }
    },
    [setIsDynamicRequired]
  );

  const changeDynamicRequiredCompanyType = useCallback(
    (value, formik) => {
      // PUBLIC COMPANY LIMITED BY GUARANTEE
      let dynamicRequire = value != 7;
      setIsDynamicRequiredCompanyType(dynamicRequire);
    },
    [setIsDynamicRequiredCompanyType]
  );

  const {
    options: identificationTypeOptions,
    loading: loadingIdentificationTypes,
    rawData: identificationTypes = [],
    refetch: fetchIdentificationTypes,
  } = useCommonOptions(commonApi.getIdentificationType, {
    respondKey: 'identificationtypes',
    labelKey: 'identification_name',
    valueKey: 'identification_type_id',
  });

  const {
    options: companyOptions = [],
    loading: loadingCompanies,
    rawData: companies = [],
  } = useCommonOptions(async () => companyApi.getCompanies('', 1, 10000), {
    respondKey: 'companies',
    labelKey: 'company_name',
    valueKey: 'company_id',
    render: (_, anItem) => {
      return `${anItem.company_name} (${anItem.company_registration_no})`;
    },
  });

  // company address
  const [companyAddressForm, setCompanyAddressForm] = useState({
    show: false,
    data: null,
  });
  const [selectedCompanyAddresses, setSelectedCompanyAddresses] = useState([]);
  console.log('selectedCompanyAddresses', selectedCompanyAddresses);
  const handleSubmitCompanyAddressForm = (values) => {
    let is_default = values?.is_default ? 1 : 0;

    if (companyAddressForm?.data) {
      setSelectedCompanyAddresses((prev) => {
        console.log('prev', prev);
        let newResult = prev.map((anItem, index) => {
          let newItemValue = anItem;
          if (anItem.id != undefined && anItem.id == companyAddressForm?.data.id) {
            newItemValue = {
              ...anItem,
              ...values,
              is_default,
              isEdited: true,
            };
          }
          return newItemValue;
        });

        return newResult;
      });
    } else {
      setSelectedCompanyAddresses((prev) => {
        let newResult = prev.map((anItem) => {
          let newItemValue = anItem;
          if (is_default) {
            if (newItemValue['is_default'] != 0 && !newItemValue?.isNew) {
              newItemValue['isEdited'] = true;
            }
            newItemValue['is_default'] = 0;
          }
          return newItemValue;
        });
        let result = [...newResult, { ...values, isNew: true, is_default }];
        result = result.map((anItem2, index2) => {
          return {
            ...anItem2,
            id: index2,
          };
        });
        return result;
      });
    }

    setCompanyAddressForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveCompanyAddress = (value) => {
    setSelectedCompanyAddresses((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem.id == value.id) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      }
      return prev.filter((anItem) => {
        return anItem.id != value.id;
      });
    });
    setCompanyAddressForm({
      show: false,
      data: null,
    });
  };

  // company auditors
  const [auditorCompanyForm, setAuditorCompanyForm] = useState({
    show: false,
    data: null,
  });
  const [selectedAuditorCompanies, setSelectedAuditorCompanies] = useState([]);

  const handleSubmitAuditorCompanyForm = (values) => {
    if (auditorCompanyForm?.data) {
      setSelectedAuditorCompanies((prev) => {
        let newValue = selectedAuditorCompanies.map((anItem) => {
          if (auditorCompanyForm?.data?.company_id == anItem.company_id) {
            return {
              ...anItem,
              ...values,
            };
          }
          return anItem;
        });
        return newValue;
      });
    } else {
      setSelectedAuditorCompanies((prev) => {
        return [...prev, values];
      });
    }

    setAuditorCompanyForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveAuditorCompany = (value) => {
    setSelectedAuditorCompanies((prev) => {
      return prev.filter((anItem) => {
        return anItem.company_id != value?.company_id;
      });
    });
    setAuditorCompanyForm({
      show: false,
      data: null,
    });
  };

  // company super admins
  const [superAdminForm, setSuperAdminForm] = useState({
    show: false,
    data: null,
  });
  const [selectedSuperAdmins, setSelectedSuperAdmins] = useState([]);

  const handleSubmitSuperAdminForm = (values) => {
    if (superAdminForm?.data) {
      setSelectedSuperAdmins((prev) => {
        let newResult = prev.map((anItem) => {
          if (anItem.email == values.email) {
            return {
              ...anItem,
              ...values,
            };
          }
          return anItem;
        });

        return newResult;
      });
    } else {
      setSelectedSuperAdmins((prev) => {
        return [...prev, values];
      });
    }

    setSuperAdminForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveSuperAdmin = (value) => {
    setSelectedSuperAdmins((prev) => {
      return prev.filter((anItem) => {
        return anItem.email != value.email;
      });
    });
    setSuperAdminForm({
      show: false,
      data: null,
    });
  };

  // shareholders
  const [shareholderForm, setShareholderForm] = useState({
    show: false,
    data: null,
  });
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const tempShareholderId = 'row_id';

  const {
    options: shareTypeOptions,
    loading: loadingShareTypes,
    rawData: shareTypes = [],
  } = useCommonOptions(commonApi.getShareType, {
    respondKey: 'share_types',
    labelKey: 'share_type_name',
    valueKey: 'share_type_id',
  });

  const getTotalShareholderShares = (shareType) => {
    let totalHoldingShares = 0;
    selectedShareholders.forEach((aShareholder) => {
      if (aShareholder.user_sharetype == shareType) {
        let aShareNum = parseInt(aShareholder['user_sharenumber']);
        totalHoldingShares += aShareNum;
      }
    });
    return totalHoldingShares;
  };

  const handleSubmitShareholder = (values) => {
    let isNew = shareholderForm?.data ? false : true;
    if (isNew) {
      setSelectedShareholders((prev) => [...prev, { ...values, [tempShareholderId]: prev.length }]);
    } else {
      setSelectedShareholders((prev) => {
        return prev.map((anItem) => {
          if (anItem[tempShareholderId] == values[tempShareholderId]) {
            return {
              ...anItem,
              ...values,
            };
          }
          return anItem;
        });
      });
    }
    setShareholderForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveShareholder = () => {
    setSelectedShareholders((prev) => {
      return prev.filter((anItem) => {
        return anItem?.[tempShareholderId] != shareholderForm?.data?.[tempShareholderId];
      });
    });
    setShareholderForm({
      show: false,
      data: null,
    });
  };

  // officers
  const [officerForm, setOfficerForm] = useState({
    show: false,
    data: null,
  });
  const [selectedOfficers, setSelectedOfficers] = useState([]);

  const {
    options: companyRoleOptions,
    loading: loadingCompanyRoles,
    rawData: companyRoles = [],
  } = useCommonOptions(commonApi.getCompanyRole, {
    respondKey: 'companyroles',
    labelKey: 'role_name',
    valueKey: 'company_role_id',
  });
  const { options: userOptions, loading: loadingUsers, rawData: users = [] } = useCommonOptions(
    async () => await userApi.getUsers('', 1, 100000),
    {
      respondKey: 'users',
      labelKey: 'email',
      valueKey: 'user_id',
    }
  );

  const handleSubmitOfficer = (values) => {
    if (officerForm?.data) {
      setSelectedOfficers((prev) => {
        let newValue = prev.map((anItem) => {
          if (officerForm?.data?.user_company_role_id == anItem.user_company_role_id) {
            return {
              ...anItem,
              ...values,
            };
          }
          return anItem;
        });
        return newValue;
      });
    } else {
      setSelectedOfficers((prev) => {
        return [...prev, { ...values, user_company_role_id: prev.length }];
      });
    }

    setOfficerForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveOfficer = (value) => {
    setSelectedOfficers((prev) => {
      let newResult = prev.filter((anItem) => {
        return anItem.user_company_role_id != value?.user_company_role_id;
      });
      newResult = newResult.map((anItem, index) => {
        return {
          ...anItem,
          user_company_role_id: index,
        };
      });
      return newResult;
    });
    setOfficerForm({
      show: false,
      data: null,
    });
  };

  // documents
  const [selectedDocuments, setSelectedDocuments] = useState(defaultDocumentGroups);

  const ref_certificateOfIncorporation = useRef();
  const ref_bizFile = useRef();
  const ref_annualReport = useRef();
  const ref_auditedReport = useRef();
  const ref_constitutionalDocument = useRef();
  const ref_managementAccounts = useRef();

  const { options: companyTypeOptions, loading: loadingCompanyTypes } = useCommonOptions(
    commonApi.getCompanyType,
    {
      respondKey: 'company_types',
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
    }
  );

  const { options: companyStatusOptions, loading: loadingCompanyStatuses } = useCommonOptions(
    commonApi.getCompanyStatus,
    {
      respondKey: 'company_statuses',
      labelKey: 'company_status_name',
      valueKey: 'company_status_id',
      render: (value, data) => {
        return `${data?.status_category} - ${data?.company_status_name}`;
      },
    }
  );

  const { options: countryOptions, loading: loadingCountries } = useCommonOptions(
    commonApi.getCountry,
    {
      respondKey: 'countries',
      labelKey: 'country_name',
      valueKey: 'country_id',
    }
  );

  const { options: ssicOptions, loading: loadingSsic } = useCommonOptions(commonApi.getSsicCode, {
    respondKey: 'document',
    labelKey: 'ssic_name',
    valueKey: 'ssic_id',
  });

  const { options: addressTypeOptions, loading: loadingAddressTypes } = useCommonOptions(
    commonApi.getAddressType,
    {
      respondKey: 'addresstypes',
      labelKey: 'address_type_name',
      valueKey: 'address_type_id',
    }
  );

  let isLoading =
    loadingCompanyTypes ||
    loadingCompanyStatuses ||
    loadingCountries ||
    loadingSsic ||
    loadingAddressTypes;

  let initialValues = validationSchema.cast();

  const onSubmit = (values, actions) => {
    const dynamicRequireFields = {
      [fields.incorporation_date.name]: '', // 0000-00-00
      // [fields.company_type_id.name]: null,
      // [fields.company_status_id.name]: null,
      [fields.status_date.name]: '',
      [fields.primary_ssic_id.name]: '',
    };
    console.log('onSubmit values: ', values);
    setLoadingSubmit(true);
    const startSubmit = async () => {
      // create company
      let companyBody = {
        created_by: parseInt(authUserId),
      };
      let companyDetailKeys = Object.keys(fields_companyDetail);
      companyDetailKeys.forEach((aKey) => {
        let fieldName = fields_companyDetail?.[aKey]?.name;
        if (fieldName) {
          if (values?.[fieldName]) {
            companyBody[fieldName] = values?.[fieldName];
            if (fieldName == 'country_of_incorporation_id') {
              companyBody['country_of_operation_id'] = values?.[fieldName];
            }
          } else {
            let foundDynamicRequireField = dynamicRequireFields?.[fieldName] != undefined;
            if (foundDynamicRequireField) {
              companyBody[fieldName] = dynamicRequireFields?.[fieldName];
            }
          }
        }
      });

      companyBody['company_client_type_id'] = values?.['company_client_type_id']
        ? parseInt(values?.['company_client_type_id'])
        : null;

      let companyRes;
      try {
        companyRes = await companyApi.createCompany(companyBody);
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Create Company`, {
          variant: 'error',
        });
        return;
      }

      let companyId = companyRes?.data?.company?.company_id;
      if (companyRes?.data?.is_success) {
        if (
          ref_certificateOfIncorporation?.current?.value !== undefined ||
          ref_certificateOfIncorporation?.current?.value !== null ||
          ref_certificateOfIncorporation?.current?.value !== ''
        ) {
          try {
            await fileUploader(ref_certificateOfIncorporation, 18, 2, companyId);
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message ||
                `Failed to Upload Certificate Of Incorporation`,
              { variant: 'error' }
            );
            // return;
          }
        }

        // principal activity
        let principalActivitiesBody = {
          created_by: parseInt(authUserId),
        };
        let principalActivityKeys = Object.keys(fields_principalActivities);
        principalActivityKeys.forEach((aKey) => {
          let fieldName = fields_principalActivities?.[aKey]?.name;
          if (fieldName && values?.[fieldName]) {
            principalActivitiesBody[fieldName] = values?.[fieldName];
          }
        });

        if (Object.keys(principalActivitiesBody).length > 1) {
          try {
            let businessRes = await businessApi.createBusinessActivity(
              companyId,
              principalActivitiesBody
            );
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message || `Failed to Create Principal Activity`,
              { variant: 'error' }
            );
            // return;
          }
        }

        if (
          ref_bizFile?.current?.value !== undefined ||
          ref_bizFile?.current?.value !== null ||
          ref_bizFile?.current?.value !== ''
        ) {
          try {
            await fileUploader(ref_bizFile, 15, 2, companyId);
          } catch (error) {
            enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Upload Biz File`, {
              variant: 'error',
            });
            // return;
          }
        }

        // capital
        let shareData = values?.[shareCapitalFieldNames.shareType_ordinary.name];
        const preferenceShareData = values?.[shareCapitalFieldNames.shareType_preference.name];
        const otherShareData = values?.[shareCapitalFieldNames.shareType_others.name];

        let respShare, respPreferenceShare, respOtherShare;
        try {
          if (
            shareData &&
            shareData.issued_share_capital !== undefined &&
            shareData.number_of_shares !== undefined &&
            shareData.paid_up_capital !== undefined &&
            shareData.currency_id !== undefined
          ) {
            respShare = await shareApi.createShareCapital(companyId, shareData);
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Create Share Capital (Ordinary)`,
            { variant: 'error' }
          );
          // return;
        }

        try {
          if (
            preferenceShareData &&
            preferenceShareData.issued_share_capital !== undefined &&
            preferenceShareData.number_of_shares !== undefined &&
            preferenceShareData.paid_up_capital !== undefined &&
            preferenceShareData.currency_id !== undefined
          ) {
            respPreferenceShare = await shareApi.createShareCapital(companyId, preferenceShareData);
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Create Share Capital (Preference)`,
            { variant: 'error' }
          );
          // return;
        }

        try {
          if (
            otherShareData &&
            otherShareData.issued_share_capital !== undefined &&
            otherShareData.number_of_shares !== undefined &&
            otherShareData.paid_up_capital !== undefined &&
            otherShareData.currency_id !== undefined
          ) {
            respOtherShare = await shareApi.createShareCapital(companyId, otherShareData);
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Create Share Capital (Others)`,
            { variant: 'error' }
          );
          // return;
        }

        // treasury share
        if (respShare?.data?.shares?.share_capital_id) {
          let treasuryShareBody = {
            created_by: parseInt(authUserId),
          };
          let treasuryShareKeys = Object.keys(fields_treasuryShares);
          treasuryShareKeys.forEach((aKey) => {
            let fieldName = fields_treasuryShares?.[aKey]?.name;
            if (fieldName && values?.[fieldName]) {
              treasuryShareBody[fieldName] = values?.[fieldName];
            }
          });
          if (
            Object.keys(treasuryShareBody).length > 0 &&
            treasuryShareBody?.[fields_treasuryShares.number_of_shares.name]
          ) {
            try {
              const respTreasury = await shareApi.createTreasuryShare(
                companyId,
                respShare.data.shares.share_capital_id,
                treasuryShareBody
              );
            } catch (error) {
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Create Treasury Share`,
                { variant: 'error' }
              );
              // return;
            }
          }
        }

        // address
        // let companyAddressBody = {
        //   address_line_3: null,
        //   is_default: 1,
        //   is_applying: 0,
        //   is_registered: 1,
        //   registration_datetime: '2021-03-31 00:00:00',
        //   created_by: parseInt(authUserId),
        // };
        // let companyAddressKeys = Object.keys(fields_registeredAddress);
        // companyAddressKeys.forEach((aKey) => {
        //   let fieldName = fields_registeredAddress?.[aKey]?.name;
        //   if (fieldName) {
        //     companyAddressBody[fieldName] = values?.[fieldName];
        //   }
        // });

        // try {
        //   const respCompanyAddress = await addressApi.createCompanyAddress(companyId, {
        //     companyAddresses: [companyAddressBody],
        //   });
        // } catch (error) {
        //   enqueueSnackbar(
        //     error?.response?.data?.error?.message || `Failed to Create Company Address`,
        //     { variant: 'error' }
        //   );
        //   // return;
        // }

        if (selectedCompanyAddresses?.length > 0) {
          let newAddresses = [];
          for (var i = 0; i < selectedCompanyAddresses.length; i++) {
            let addressData = selectedCompanyAddresses[i];

            let companyAddressData = {
              ...addressData,
            };

            newAddresses.push({
              address_line_3: '',
              is_default: 1,
              is_applying: 0,
              is_registered: 1,
              registration_datetime: '2021-03-31 00:00:00',
              ...companyAddressData,
            });
          }

          if (newAddresses?.length > 0) {
            try {
              const respCompanyAddress = await addressApi.createCompanyAddress(companyId, {
                companyAddresses: newAddresses,
              });
            } catch (error) {
              enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Address`, {
                variant: 'error',
              });
              // return;
            }
          }
        }

        // Documents
        // if (
        //   ref_annualReport?.current?.value !== undefined ||
        //   ref_annualReport?.current?.value !== null ||
        //   ref_annualReport?.current?.value !== ''
        // ) {
        //   try {
        //     await fileUploader(ref_annualReport, 5, 2, companyId);
        //   } catch (error) {
        //     enqueueSnackbar(
        //       error?.response?.data?.error?.message || `Failed to Upload Annual Report`,
        //       { variant: 'error' }
        //     );
        //     // return;
        //   }
        // }

        // if (
        //   ref_auditedReport?.current?.value !== undefined ||
        //   ref_auditedReport?.current?.value !== null ||
        //   ref_auditedReport?.current?.value !== ''
        // ) {
        //   try {
        //     await fileUploader(ref_auditedReport, 12, 2, companyId);
        //   } catch (error) {
        //     enqueueSnackbar(
        //       error?.response?.data?.error?.message || `Failed to Upload Audited Report`,
        //       { variant: 'error' }
        //     );
        //     // return;
        //   }
        // }

        // if (
        //   ref_constitutionalDocument?.current?.value !== undefined ||
        //   ref_constitutionalDocument?.current?.value !== null ||
        //   ref_constitutionalDocument?.current?.value !== ''
        // ) {
        //   try {
        //     await fileUploader(ref_constitutionalDocument, 28, 2, companyId);
        //   } catch (error) {
        //     enqueueSnackbar(
        //       error?.response?.data?.error?.message || `Failed to Upload Constitutional Document`,
        //       { variant: 'error' }
        //     );
        //     // return;
        //   }
        // }

        // if (
        //   ref_managementAccounts?.current?.value !== undefined ||
        //   ref_managementAccounts?.current?.value !== null ||
        //   ref_managementAccounts?.current?.value !== ''
        // ) {
        //   try {
        //     await fileUploader(ref_managementAccounts, 57, 2, companyId);
        //   } catch (error) {
        //     enqueueSnackbar(
        //       error?.response?.data?.error?.message || `Failed to Upload Management Accounts`,
        //       { variant: 'error' }
        //     );
        //     // return;
        //   }
        // }

        for (let i = 0; i < selectedDocuments.length; i++) {
          let documentsByGroup = selectedDocuments[i];
          if (documentsByGroup?.data?.length > 0) {
            let failedUploads = [];
            for (let j = 0; j < documentsByGroup.data.length; j++) {
              let groupedFiles = documentsByGroup.data[j];
              try {
                let res = await fileUploader(
                  { current: { files: [groupedFiles.file] } },
                  documentsByGroup.document_type_id,
                  2,
                  companyId
                );
                if (res?.error) {
                  failedUploads.push(res);
                }
              } catch (error) {}
            }
            if (failedUploads?.length > 0) {
              window.alert(
                `Error: Failed to Upload: \n${failedUploads.map(
                  (anItem) => `${anItem?.data?.name || 'Untitled'} (${anItem?.message})\n`
                )}`
              );
            }
          }
        }

        // officer

        for (var i = 0; i < selectedOfficers.length; i++) {
          let officerData = selectedOfficers[i];
          let companyofficerData = {
            ...officerData,
            cessation_type_id: officerData.cessation_type_id || null,
            company_id: companyId,
            created_by: parseInt(authUserId),
            nominee_id: officerData.nominee_id === 'null' ? null : officerData.nominee_id,
            nominator_id: officerData.nominator_id === 'null' ? null : officerData.nominator_id,
            controller_id: officerData.controller_id === 'null' ? null : officerData.controller_id,
            named_secretary_id:
              officerData.named_secretary_id === 'null' ? null : officerData.named_secretary_id,
          };

          try {
            const respCompanyOfficer = await companyApi.addCompanyOfficer(
              companyId,
              companyofficerData
            );
            console.log('respCompanyOfficer' + i, respCompanyOfficer);
          } catch (error) {
            enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Officer`, {
              variant: 'error',
            });
            // return;
          }
        }

        //   user_firstname: "Dolan";
        //   user_id: 5;
        //   user_idtype: "NRIC";
        //   user_idvalue: "123456789";
        //   user_sharenumber: "11";
        //   user_sharetype: 2;

        // shareholders

        for (var i = 0; i < selectedShareholders.length; i++) {
          // respShare.data.shares.share_capital_id;
          // respPreferenceShare.data.shares.share_capital_id;
          // respOtherShare.data.shares.share_capital_id;
          // if (selectedShareholders[i].user_sharetype === 1) {
          let share_capital_id;
          if (selectedShareholders[i].user_sharetype == 1) {
            share_capital_id = respShare?.data.shares.share_capital_id;
          } else if (selectedShareholders[i].user_sharetype == 2) {
            share_capital_id = respPreferenceShare?.data.shares.share_capital_id;
          } else if (selectedShareholders[i].user_sharetype == 3) {
            share_capital_id = respOtherShare?.data.shares.share_capital_id;
          }
          const shareholderData = {
            shareholder_type_id:
              selectedShareholders[i].user_idtype === 3
                ? 2
                : selectedShareholders[i].user_idtype === 2
                ? 1
                : selectedShareholders[i].user_idtype,
            table_primary_id: selectedShareholders[i].user_id,
            share_capital_id: share_capital_id,
            user_allotment: selectedShareholders[i].user_sharenumber,
            created_by: parseInt(authUserId),
          };
          try {
            const respShareholder = await shareholderApi.addShareholder(companyId, shareholderData);
            console.log('respShareholder' + i, respShareholder);
          } catch (error) {
            enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Shareholder`, {
              variant: 'error',
            });
            // return;
          }
        }

        // super admin
        for (let i = 0; i < selectedSuperAdmins.length; i++) {
          let anAdmin = selectedSuperAdmins[i];
          if (anAdmin?.user_id) {
            // create sysinfo user role only
            try {
              await userApi.createSysInfoUserRole({
                username: anAdmin?.email,
                role_id: anAdmin.role_id,
                company_id: companyId,
                sysinfo_user_id: anAdmin?.user_id,
                user_id: parseInt(authUserId),
                created_by: parseInt(authUserId),
              });
            } catch (error) {
              console.log('createSysInfoUser error', error?.response?.data?.error?.message);
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                { variant: 'error' }
              );
              // return;
            }
          } else {
            // create sysinfo user & sysinfo user role

            let newSysInfoUserBody = {
              ...anAdmin,
              created_by: parseInt(authUserId),
              username: anAdmin?.email,
              password: null,
              is_new_user: true,
            };

            let createSysInfoUserRes;
            try {
              createSysInfoUserRes = await userApi.createSysInfoUser(newSysInfoUserBody);
            } catch (error) {
              console.log('createSysInfoUser error', error?.response?.data?.error?.message);
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                { variant: 'error' }
              );
              // return;
            }

            if (createSysInfoUserRes?.data?.is_success) {
              try {
                await userApi.createSysInfoUserRole({
                  username: createSysInfoUserRes?.data?.users?.email,
                  role_id: anAdmin.role_id,
                  company_id: companyId,
                  sysinfo_user_id: createSysInfoUserRes?.data?.users?.user_id,
                  user_id: parseInt(authUserId),
                  created_by: parseInt(authUserId),
                });
              } catch (error) {
                enqueueSnackbar(
                  error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                  { variant: 'error' }
                );
                // return;
              }
            }
          }
        }

        // auditor company
        for (let i = 0; i < selectedAuditorCompanies.length; i++) {
          let anAuditorCompany = selectedAuditorCompanies[i];
          if (anAuditorCompany?.auditor_company_id) {
            try {
              await companyApi.createCompanyAuditor({
                ...anAuditorCompany,
                client_company_id: companyId,
                // company_type_id: 14,
                // company_status_id: 2,
                created_by: parseInt(authUserId),
              });
            } catch (error) {
              console.log('Add Auditor Company error', error?.response?.data?.error?.message);
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Add Auditor Company`,
                { variant: 'error' }
              );
              // return;
            }
          }
        }

        enqueueSnackbar(`Company Created`, { variant: 'success' });
        history.replace(`/companydetails?company=${companyId}`);
      }
    };

    startSubmit().finally(() => {
      setLoadingSubmit(false);
    });
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/company' })}
      >
        BACK TO COMPANY DATABASE
      </Button>
      <PageTitle title="Company Form" />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              const {
                values,
                handleSubmit,
                validateForm,
                setTouched,
                touched,
                setFieldValue,
              } = formikProps;
              console.log('valuesvalues', values);

              let incorporation_date = values?.[fields.incorporation_date.name];
              let minDate_fye;
              let maxDate_first_fye;
              let minDate_today = moment().add(1, 'days').format('YYYY-MM-DD');

              if (incorporation_date) {
                minDate_fye = moment(incorporation_date).format('YYYY-MM-DD');
                maxDate_first_fye = moment(incorporation_date)
                  .add(18, 'months')
                  .format('YYYY-MM-DD');
              }

              let defaultFirstFyeDate = values?.[fields.first_fye_date.name];
              let defaultFyeDate = values?.[fields.fye_date.name];
              if (defaultFirstFyeDate && !defaultFyeDate) {
                if (defaultFirstFyeDate && moment(defaultFirstFyeDate).isValid()) {
                  let newFye = moment(defaultFirstFyeDate).add(1, 'years');
                  if (newFye.isAfter(moment().format('YYYY-MM-DD'))) {
                    defaultFyeDate = newFye.format('YYYY-MM-DD');
                    setFieldValue(fields.fye_date.name, defaultFyeDate);
                  } else {
                    setFieldValue(
                      fields.fye_date.name,
                      moment().add(1, 'years').format('YYYY-MM-DD')
                    );
                  }
                }
              }

              let shareDatas = [];
              if (
                values?.[shareCapitalFieldNames.shareType_ordinary.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_ordinary.name],
                  share_type_name: shareCapitalFieldNames.shareType_ordinary.label,
                });
              }
              if (
                values?.[shareCapitalFieldNames.shareType_preference.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_preference.name],
                  share_type_name: shareCapitalFieldNames.shareType_preference.label,
                });
              }
              if (
                values?.[shareCapitalFieldNames.shareType_others.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_others.name],
                  share_type_name: shareCapitalFieldNames.shareType_others.label,
                });
              }

              return (
                <form onSubmit={handleSubmit}>
                  <FormikDummyField
                    field={fields.company_client_type_id.name}
                    onChange={changeDynamicRequired}
                  />
                  <FormikDummyField
                    field={fields.company_type_id.name}
                    onChange={changeDynamicRequiredCompanyType}
                  />
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Company Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomRadioGroupField
                          name={fields.company_client_type_id.name}
                          label={fields.company_client_type_id.label}
                          options={companyCategoryOptions}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.company_registration_no.name}
                          label={fields.company_registration_no.label}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.company_name.name}
                          label={fields.company_name.label}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.former_name.name}
                          label={fields.former_name.label}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.name_effective_from.name}
                          label={fields.name_effective_from.label}
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.incorporation_date.name}
                          label={fields.incorporation_date.label}
                          type="date"
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.company_type_id.name}
                          label={fields.company_type_id.label}
                          options={companyTypeOptions}
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.company_status_id.name}
                          label={fields.company_status_id.label}
                          options={companyStatusOptions}
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.status_date.name}
                          label={fields.status_date.label}
                          type="date"
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.country_of_incorporation_id.name}
                          label={fields.country_of_incorporation_id.label}
                          options={countryOptions}
                          required={true}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <small>For New Company</small>
                        </Box>
                        <CustomTextField
                          name={fields.first_fye_date.name}
                          label={fields.first_fye_date.label}
                          type="date"
                          inputProps={{
                            min: minDate_fye,
                            max: maxDate_first_fye,
                          }}
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <small>For Existing Company</small>
                        </Box>
                        <CustomTextField
                          name={fields.fye_date.name}
                          label={fields.fye_date.label}
                          type="date"
                          inputProps={{
                            min: minDate_today,
                          }}
                          required={dynamicRequire}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_certificateOfIncorporation}
                          placeholder="Upload Certificate of Incorporation"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Principal Activities
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.primary_ssic_id.name}
                          label={fields.primary_ssic_id.label}
                          options={ssicOptions}
                          required={dynamicRequire}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.business_description.name}
                          label={fields.business_description.label}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.secondary_ssic_id.name}
                          label={fields.secondary_ssic_id.label}
                          options={ssicOptions}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.secondary_business_description.name}
                          label={fields.secondary_business_description.label}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton ref={ref_bizFile} placeholder="Upload Biz File" />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Capital
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">Issued Share Capital</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ShareCapital
                          shareTypeOptions={shareTypeOptions}
                          totalAssignedShare={{
                            [shareCapitalFieldNames.shareType_ordinary
                              .name]: getTotalShareholderShares(1),
                            [shareCapitalFieldNames.shareType_preference
                              .name]: getTotalShareholderShares(2),
                            [shareCapitalFieldNames.shareType_others
                              .name]: getTotalShareholderShares(3),
                          }}
                          required={dynamicRequire == true && dynamicRequireCompanyType == true}
                          hideHeader={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">Treasury Shares</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.number_of_shares.name}
                          label={fields.number_of_shares.label}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Registered Address
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setCompanyAddressForm({
                                show: true,
                                data: null,
                              });
                            }}
                          >
                            Add Company Address
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyAddressTable
                          data={selectedCompanyAddresses}
                          onEdit={(data) => {
                            setCompanyAddressForm({
                              show: true,
                              data: data,
                            });
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.address_type_id.name}
                          label={fields.address_type_id.label}
                          options={addressTypeOptions}
                          required={true}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.floor_unit_no.name}
                          label={fields.floor_unit_no.label}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.address_line_1.name}
                          label={fields.address_line_1.label}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.address_line_2.name}
                          label={fields.address_line_2.label}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.postal_code.name}
                          label={fields.postal_code.label}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.country_id.name}
                          label={fields.country_id.label}
                          options={countryOptions}
                          required={true}
                        />
                      </Grid> */}
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Upload Documents
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%">
                          {selectedDocuments.map((anItem, index) => {
                            const handleAddDocument = (allDocs = [], selectedDocs = []) => {
                              setSelectedDocuments((prev) => {
                                return prev.map((aPrevItem) => {
                                  if (aPrevItem.document_type_id == anItem.document_type_id) {
                                    return {
                                      ...aPrevItem,
                                      data: allDocs,
                                    };
                                  }
                                  return aPrevItem;
                                });
                              });
                            };
                            const handleRemoveDocument = (allDocs = [], selectedDoc) => {
                              setSelectedDocuments((prev) => {
                                return prev.map((aPrevItem) => {
                                  if (aPrevItem.document_type_id == anItem.document_type_id) {
                                    return {
                                      ...aPrevItem,
                                      data: allDocs,
                                    };
                                  }
                                  return aPrevItem;
                                });
                              });
                            };
                            return (
                              <Box key={index} mb={2}>
                                {index != 0 ? (
                                  <Box mb={1}>
                                    <Divider />
                                  </Box>
                                ) : null}
                                <CompanyDocumentsGroupItem
                                  title={anItem.label}
                                  value={anItem.data || []}
                                  onAdd={handleAddDocument}
                                  onRemove={handleRemoveDocument}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_annualReport}
                          placeholder="Upload Annual Report"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_auditedReport}
                          placeholder="Upload Audited Report"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_constitutionalDocument}
                          placeholder="Upload Constitutional Document"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_managementAccounts}
                          placeholder="Upload Management Accounts"
                        />
                      </Grid>*/}
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Company Officers
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setOfficerForm({
                                show: true,
                                data: null,
                              });
                            }}
                          >
                            Add Company Officer
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyOfficerTable
                          data={selectedOfficers}
                          onEdit={(data) => {
                            setOfficerForm({
                              show: true,
                              data: data,
                            });
                          }}
                          companyRoleOptions={companyRoleOptions}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Shareholders
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setShareholderForm({
                                show: true,
                                data: null,
                              });
                            }}
                          >
                            Add Shareholder
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <ShareholderTable
                          data={selectedShareholders}
                          onEdit={(data) => {
                            setShareholderForm({
                              show: true,
                              data: data,
                            });
                          }}
                          shareTypeOptions={shareTypeOptions}
                        />
                        <ShareholderModalForm
                          {...shareholderForm}
                          onHide={() => {
                            setShareholderForm({
                              show: false,
                              data: null,
                            });
                          }}
                          onSubmit={handleSubmitShareholder}
                          onRemove={handleRemoveShareholder}
                          userData={users}
                          shareholders={selectedShareholders}
                          shareTypes={shareDatas}
                          identificationTypeOptions={identificationTypeOptions}
                          companies={companies}
                          companyOptions={companyOptions}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Meyzer360 Company Account Users
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setSuperAdminForm({
                                show: true,
                                data: null,
                              });
                            }}
                          >
                            Add User
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <SuperAdminTable
                          data={selectedSuperAdmins}
                          onEdit={(data) => {
                            setSuperAdminForm({
                              show: true,
                              data: data,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Company Auditors
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setAuditorCompanyForm({
                                show: true,
                                data: null,
                              });
                            }}
                          >
                            Add Company Auditor
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <AuditorCompanyTable
                          data={selectedAuditorCompanies}
                          onEdit={(data) => {
                            setAuditorCompanyForm({
                              show: true,
                              data: data,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Button
                    // type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // setLoadingSubmit(true);
                      validateForm()
                        .then((result) => {
                          console.log('validateForm result', result);
                          let canSubmit = false;
                          if (result && Object.keys(result).length == 0) {
                            canSubmit = true;
                          }
                          if (canSubmit) {
                            onSubmit(values);
                          } else {
                            // setLoadingSubmit(false);
                            enqueueSnackbar(`Please fill up all required fields`, {
                              variant: 'error',
                            });
                            setTouched({ ...touched, ...result });
                          }
                        })
                        .catch((errors) => {
                          console.log('validateForm error', errors);
                          enqueueSnackbar(`Please fill up all required fields`, {
                            variant: 'error',
                          });
                          // setLoadingSubmit(false);
                          setTouched({ ...touched, ...errors });
                        });
                    }}
                  >
                    Submit
                  </Button>
                </form>
              );
            }}
          </Formik>
          <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <AuditorCompanyModalForm
            {...auditorCompanyForm}
            onHide={() => {
              setAuditorCompanyForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitAuditorCompanyForm}
            onRemove={handleRemoveAuditorCompany}
          />
          <SuperAdminModalForm
            {...superAdminForm}
            onHide={() => {
              setSuperAdminForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitSuperAdminForm}
            onRemove={handleRemoveSuperAdmin}
            superAdmins={selectedSuperAdmins}
          />
          <CompanyOfficerModalForm
            {...officerForm}
            onHide={() => {
              setOfficerForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitOfficer}
            onRemove={handleRemoveOfficer}
            userData={users}
          />
          <CompanyAddressModalForm
            {...companyAddressForm}
            onHide={() => {
              setCompanyAddressForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitCompanyAddressForm}
            onRemove={handleRemoveCompanyAddress}
          />
        </>
      )}
    </>
  );
};

export default CompanyForm;
