import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import productSvc from '../../services/product.service';
import documentSvc from '../../services/document.service';
import { useUserState } from '../../context/UserContext';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import useCommonOptions from '../../hooks/useCommonOptions';
import ProductImages from './components/ProductImages';
import fileuploader, { productImageUploader } from '../../utility/fileuploader';
/*

name: "Resignation of Administrator"
description: "Description"
product_category: "Corporate Secretarial Services"
product_category_id: 1
item_category: null
item_category_id: null
quantity: null
price: 0
sku: null
subscription_type_id: 1
additional_info: null
need_delivery: null
commission_rate: null
product_status: null

id: 1
created_by: 0
updated_by: 0
status_id: null

// not confirmed
unit: null
unit_id: null
available: null
delivery_limit: null
variety: null
location: null
discount: null



*/

const fields = {
  // Company Details
  name: {
    name: 'name',
    label: 'Name',
    required: true,
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  product_category_id: {
    name: 'product_category_id',
    label: 'Product Category',
    required: true,
  },
  item_category_id: {
    name: 'item_category_id',
    label: 'Item Category',
    required: true,
  },
  quantity: {
    name: 'quantity',
    label: 'Quantity',
  },
  price: {
    name: 'price',
    label: 'Price',
    required: true,
  },
  sku: {
    name: 'sku',
    label: 'SKU',
  },
  subscription_type_id: {
    name: 'subscription_type_id',
    label: 'Subscription Type',
  },
  additional_info: {
    name: 'additional_info',
    label: 'Additional Info',
  },
  need_delivery: {
    name: 'need_delivery',
    label: 'Need Delivery',
  },
  commission_rate: {
    name: 'commission_rate',
    label: 'Commission Rate',
  },
  product_status: {
    name: 'product_status',
    label: 'Product Status',
  },
};
const validationSchema = Yup.object({
  [fields.name.name]: Yup.string().required(`${fields.name.label} is required`),
  [fields.description.name]: Yup.string().nullable().default(null),
  [fields.product_category_id.name]: Yup.string().required(
    `${fields.product_category_id.label} is required`
  ),
  [fields.item_category_id.name]: Yup.string().nullable().default(null),
  // [fields.quantity.name]: Yup.number().required(`${fields.quantity.label} is required`).nullable(),
  [fields.quantity.name]: Yup.number().nullable().default(null),
  [fields.price.name]: Yup.number().required(`${fields.price.label} is required`),
  [fields.sku.name]: Yup.string().nullable().default(null),
  [fields.subscription_type_id.name]: Yup.string().nullable().default(null),
  [fields.additional_info.name]: Yup.string().nullable().default(null),
  [fields.commission_rate.name]: Yup.number().nullable().default(null),
  [fields.product_status.name]: Yup.string().nullable().default(null),
});

const mergeProductAndImage = (products = [], documents = []) => {
  let result = products.map((aProduct) => {
    if (aProduct?.product_documents?.length > 0) {
      let newProductDocuments = aProduct?.product_documents.map((aProductDocument) => {
        let foundDoc = documents.find((aDoc) => {
          return aDoc.document_id == aProductDocument.document_id;
        });
        if (foundDoc) {
          return {
            ...aProductDocument,
            document: foundDoc,
          };
        }
        return aProductDocument;
      });
      return {
        ...aProduct,
        product_documents: newProductDocuments,
      };
    }
    return aProduct;
  });
  return result;
};

export default function ProductDetail(props) {
  const { product, show, onHide, refetch, productCategoryId } = props;
  // const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const user = useUserState();
  let isNew = product?.id ? false : true;

  const [productImages, setProductImages] = useState([]);

  const ListProductDocuments = async (products = []) => {
    let ids = [];
    products.forEach((aProduct) => {
      if (aProduct?.product_documents?.length > 0) {
        aProduct?.product_documents.forEach((anItem) => {
          ids.push(anItem?.document_id);
        });
      }
    });
    let res;
    if (ids?.length > 0) {
      try {
        res = await documentSvc.getProductImageDocumentsByIds(ids);
        if (res?.data?.documents?.length > 0) {
          let documents = res?.data?.documents?.length > 0 ? res?.data?.documents : [];
          let mergedProducts = mergeProductAndImage(products, documents);

          return mergedProducts;
        }
      } catch (error) {
        // setProductError(error);
      }
    }

    return products;
  };

  useEffect(() => {
    (async () => {
      if (show) {
        if (product?.product_documents?.length > 0) {
          try {
            let productsWithImage = await ListProductDocuments([product]);
            console.log('productsWithImage', productsWithImage);
            setProductImages(productsWithImage?.[0]?.product_documents);
          } catch (error) {
            setProductImages([]);
          }

          // let ids = product?.product_documents.map((anItem) => {
          //   return anItem.document_id;
          // });
          // documentSvc
          //   .getDocumentsByIds(ids)
          //   .then((result) => {
          //     if (result?.data?.documents?.length > 0) {
          //       let productDocuments = product?.product_documents || [];
          //       productDocuments = productDocuments.map((anItem) => {
          //         let foundDoc = result?.data?.documents.find(
          //           (aDoc) => aDoc.document_id == anItem.document_id
          //         );
          //         if (foundDoc) {
          //           return {
          //             ...anItem,
          //             document: foundDoc,
          //           };
          //         }
          //         return anItem;
          //       });
          //       setProductImages(productDocuments);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log('getDocumentsByIds error', error);
          //   });
        }
        // setProductImages(product?.product_documents || []);
      }
    })();
  }, [show]);
  console.log('productImages', productImages);
  // const { loading: loadingProductImages, rawData: defaultProductImages } = useCommonOptions(
  //   productSvc.getProductImages,
  //   {
  //     respondKey: 'product_documents',
  //     labelKey: 'file_name',
  //     valueKey: 'id',
  //   }
  // );

  // const [productImages, setProductImages] = useState([]);

  // useEffect(() => {
  //   if (!isNew) {
  //     let productImages = [];
  //     if (defaultProductImages?.length > 0) {
  //       productImages = defaultProductImages.filter((anItem) => {
  //         return anItem.product_id == product.id;
  //       });
  //     }

  //     setProductImages(productImages);
  //   } else {
  //     setProductImages([]);
  //   }
  // }, [product?.id, show, loadingProductImages]);

  const formikOnSubmit = async (values, actions) => {
    const {
      item_category,
      product_category,
      unit,
      product_status,
      updated_at,
      product_documents,
      ...restValues
    } = values;

    let foundDocumentType = productCategoryDocumentType.find(
      (anItem) => anItem.product_category_id == restValues?.[fields.product_category_id.name]
    );
    setLoading(true);
    if (isNew) {
      try {
        let productRes = await productSvc.create({
          ...restValues,
          price: +values.price,
          created_by: user.userId,
        });

        if (productRes?.id && productImages?.length > 0) {
          for (let i = 0; i < productImages?.length; i++) {
            let productImage = productImages[i];
            if (productImage?.isNew && productImage.file) {
              let fileRes = await productImageUploader(
                { current: { files: [productImage.file] } },
                foundDocumentType?.document_type_id,
                foundDocumentType?.document_table_type_id,
                product?.id,
                undefined,
                3
                // async (data) => {
                //   return await productSvc.addProductImage({
                //     product_id: productRes?.id,
                //     image_url: data?.id,
                //     file_type: data?.file_type,
                //     file_name: data?.file_name,
                //     created_by: user.userId,
                //   });
                // }
              );
              let newDocument = fileRes?.data?.document?.[0];
              let newDocumentId = newDocument?.document_id;
              if (newDocumentId) {
                await productSvc.addProductImage({
                  product_id: product?.id,
                  document_id: newDocumentId,
                  file_type: newDocument?.file_type,
                  file_name: newDocument?.file_name,
                  created_by: user.userId,
                });
              }
            }
          }
        }
      } catch (error) {
        setLoading(false);
        return;
      }
    } else {
      try {
        await productSvc.update({
          ...restValues,
          id: product?.id,
          price: +values.price,
          updated_by: parseInt(user.userId),
        });
      } catch (error) {
        setLoading(false);
        return;
      }

      try {
        if (product?.id && productImages?.length > 0) {
          for (let i = 0; i < productImages?.length; i++) {
            let productImage = productImages[i];
            if (productImage?.isNew && productImage.file) {
              let fileRes = await productImageUploader(
                { current: { files: [productImage.file] } },
                foundDocumentType?.document_type_id,
                foundDocumentType?.document_table_type_id,
                product?.id,
                undefined
                // async (data) => {
                //   return await productSvc.addProductImage({
                //     product_id: product?.id,
                //     image_url: data?.document_url,
                //     file_type: data?.file_type,
                //     file_name: data?.file_name,
                //     created_by: user.userId,
                //   });
                // }
              );
              let newDocument = fileRes?.data?.document?.[0];
              let newDocumentId = newDocument?.document_id;
              if (newDocumentId) {
                await productSvc.addProductImage({
                  product_id: product?.id,
                  document_id: newDocumentId,
                  file_type: newDocument?.file_type,
                  file_name: newDocument?.file_name,
                  created_by: user.userId,
                });
              }
            } else {
              if (productImage?.isDeleted && productImage?.id) {
                // remove image
                await productSvc.removeProductImage(product?.id, productImage?.document_id);
              }
            }
          }
        }
      } catch (error) {
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    if (refetch) {
      refetch();
    }
    if (onHide) {
      onHide();
    }
  };

  let initialValues = product ? product : validationSchema.cast();
  if (productCategoryId != undefined && isNew) {
    initialValues[fields.product_category_id.name] = productCategoryId;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const { handleSubmit } = formikProps;
          return (
            <Form onSubmit={handleSubmit}>
              <ProductFormFields formik={formikProps} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box mb={1}>Images</Box>
                  <ProductImages
                    value={productImages}
                    onChange={(value) => {
                      setProductImages(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="primary"
                      disabled={!(formikProps.isValid || formikProps.dirty)}
                      type="submit"
                      variant="contained"
                      // style={{
                      //   backgroundColor: !(props.isValid && props.dirty) ? null : '#DEAD00',
                      //   color: '#FFFFFF',
                      //   borderRadius: 50,
                      //   width: 180,
                      //   textTransform: 'initial',
                      //   fontWeight: 600,
                      // }}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Backdrop open={isLoading} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const ProductFormFields = (props) => {
  const { formik } = props;
  const { handleSubmit, values, setFieldValue } = formik;

  const selectedProductCategoryId = values?.[fields.product_category_id.name];
  const { options: productCategoryOptions, loading: loadingProductCategories } = useCommonOptions(
    productSvc.getProductCategories,
    {
      respondKey: 'product_categories',
      labelKey: 'name',
      valueKey: 'id',
    }
  );
  const {
    options: defaultProductItemCategoryOptions,
    loading: loadingProductItemCategories,
    rawData: productItemCategories,
    refetch: fetchProductItemCategories,
  } = useCommonOptions(productSvc.getProductItemCategories, {
    respondKey: 'item_categories',
    labelKey: 'name',
    valueKey: 'id',
    // lazyLoad: true,
  });
  const { options: productStatusOptions, loading: loadingProductStatuses } = useCommonOptions(
    productSvc.getProductStatuses,
    {
      respondKey: 'product_statuses',
      labelKey: 'name',
      valueKey: 'id',
    }
  );

  const [productItemCategoryOptions, setProductItemCategoryOptions] = useState([]);

  useEffect(() => {
    if (selectedProductCategoryId != undefined && productItemCategories?.length > 0) {
      let newProductItemCategoryOptions = [];
      productItemCategories.forEach((anItem) => {
        if (anItem.product_category_id == selectedProductCategoryId) {
          newProductItemCategoryOptions.push({
            label: anItem.name,
            value: anItem.id,
          });
        }
      });
      setProductItemCategoryOptions(newProductItemCategoryOptions);
    } else {
      setProductItemCategoryOptions([]);
    }
    setFieldValue(fields.item_category_id.name, '');
  }, [selectedProductCategoryId]);

  let isLoading =
    loadingProductCategories || loadingProductItemCategories || loadingProductStatuses;

  const subscriptionTypeOptions = [
    {
      label: 'One Off',
      value: 1,
    },
    {
      label: 'Annual Recurring',
      value: 2,
    },
  ];
  // useEffect(() => {
  //   if (selectedProductCategoryId != undefined) {
  //     (async () => {
  //       let res = await fetchProductItemCategories();
  //       if (res?.data?.item_categories?.length > 0) {
  //         let newProductItemCategoryOptions = [];
  //         res?.data?.item_categories.forEach((anItem) => {
  //           if (anItem.product_category_id == selectedProductCategoryId) {
  //             newProductItemCategoryOptions.push({
  //               label: anItem.name,
  //               value: anItem.id,
  //             });
  //           }
  //         });
  //       } else {
  //         setProductItemCategoryOptions([]);
  //       }
  //     })();
  //   } else {
  //     setProductItemCategoryOptions([]);
  //   }
  // }, [selectedProductCategoryId]);

  if (isLoading) {
    return (
      <Box
        minHeight="400px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Loading
      </Box>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CustomTextField name={fields.name.name} label={fields.name.label} required={true} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField name={fields.sku.name} label={fields.sku.label} />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          name={fields.description.name}
          label={fields.description.label}
          multiline
          rows={4}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomSelectField
          name={fields.product_category_id.name}
          label={fields.product_category_id.label}
          options={productCategoryOptions}
          required={true}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomSelectField
          name={fields.item_category_id.name}
          label={fields.item_category_id.label}
          options={productItemCategoryOptions}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name={fields.quantity.name}
          label={fields.quantity.label}
          // required={true}
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name={fields.price.name}
          label={fields.price.label}
          required={true}
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomSelectField
          name={fields.subscription_type_id.name}
          label={fields.subscription_type_id.label}
          options={subscriptionTypeOptions}
          required={true}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          name={fields.additional_info.name}
          label={fields.additional_info.label}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name={fields.commission_rate.name}
          label={fields.commission_rate.label}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomSelectField
          name={fields.product_status.name}
          label={fields.product_status.label}
          options={productStatusOptions}
        />
      </Grid>
      {/* <Grid item xs={6}>
        <CustomSelectField
          name={fields.company_type_id.name}
          label={fields.company_type_id.label}
          options={companyTypeOptions}
          required={dynamicRequire}
        />
      </Grid> */}
    </Grid>
  );
};

const productCategoryDocumentType = [
  {
    document_type_id: 164,
    document_table_type_id: 28,
    product_category_id: 1,
  },
  {
    document_type_id: 157,
    document_table_type_id: 26,
    product_category_id: 2,
  },
  {
    document_type_id: 158,
    document_table_type_id: 27,
    product_category_id: 3,
  },
];
//   id int NOT NULL AUTO_INCREMENT,
//   name varchar(45) DEFAULT NULL,
//   description varchar(45) DEFAULT NULL,
//   price double DEFAULT '0',
//   created_by int DEFAULT NULL,
//   created_at varchar(45) DEFAULT NULL,
//   updated_at varchar(45) DEFAULT NULL,
//   updated_by int DEFAULT NULL,
//   location varchar(45) DEFAULT NULL,
//   sku varchar(45) DEFAULT NULL,
//   discount double DEFAULT NULL,
//   status_id int DEFAULT NULL,
//   unit_id int DEFAULT NULL,
//   sold int DEFAULT NULL,
//   available int DEFAULT NULL,
//   product_category_id int DEFAULT NULL,
//   need_delivery int DEFAULT NULL,
//   subscription_type_id int DEFAULT NULL,
//   delivery_limit int DEFAULT NULL,
//   quantity int DEFAULT NULL,
//   additional_info longtext,
//   item_category_id int DEFAULT NULL,
//   variety longtext,
//   commission_rate varchar(45) DEFAULT NULL,

// 155	Email Templates - Onboarding Company Request
// 156	Email Templates - User Invitation Manage User
// 157	Office Supplies
// 158	Software Licences
// 159	KYC - Meyzer Corpsec (Operation)
// 160	Agreements - Meyzer Corpsec (Operation)
// 161	Correspondance - Meyzer Corpsec (Operation)
// 162	Onboarding Digital Banking Users - Transwap (Operation)
// 163	Invoices - Meyzer Corpsec (Operation)
