import React, { useState, useRef } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import FileViewerButton2 from '../../../components/FileViewerButton2';

const ProductImages = React.forwardRef((props, ref) => {
  const { value, onChange, limit } = props;

  const imageInputRef = useRef();
  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const handleOnSelect = (e) => {
    if (
      imageInputRef.current.value !== undefined ||
      imageInputRef.current.value !== null ||
      ref.current.value !== ''
    ) {
      let newFiles = Array.from(imageInputRef?.current?.files);

      newFiles = newFiles.map((anItem) => {
        return {
          file: anItem,
          isNew: true,
        };
      });
      handleOnChange([...value, ...newFiles]);
      imageInputRef.current.value = '';

      //   console.log('imageInputRef.current.value', imageInputRef.current.files);
      if (!imageInputRef?.current?.files?.[0]?.type) {
      } else {
      }
    }
    // if (onChange) {
    //   onChange([...value,...newFiles]);
    // }
  };

  const handleOnRemove = (selectedValue, valueIndex) => {
    let isNew = selectedValue?.isNew ? true : false;
    if (isNew) {
      let newValue = [...value];
      newValue.splice(valueIndex, 1);
      handleOnChange(newValue);
    } else {
      let newValue = value.map((anItem, index) => {
        if (index == valueIndex) {
          return {
            ...anItem,
            isDeleted: true,
          };
        }
        return anItem;
      });

      handleOnChange(newValue);
    }
  };

  const getImagesThumbnails = (images = []) => {
    let result = [];

    images.forEach((anItem, index) => {
      let isNew = anItem?.isNew;
      let isDeleted = anItem?.isDeleted;

      let previewProps = {};
      if (isNew) {
        previewProps = {
          srcType: 'file',
          //   src: URL.createObjectURL(anItem?.file),
          src: anItem?.file,
          ...anItem?.file,
        };
      } else {
        previewProps = {
          srcType: 'url',
          // src: anItem?.image_url,
          src: anItem?.document?.document_url,
          ...anItem,
        };
      }
      //   console.log('previewProps', previewProps);
      if (!isDeleted) {
        result.push(
          <Box
            key={index}
            border={1}
            mr={1}
            mb={1}
            overflow="hidden"
            style={{ width: '100px', height: '100px' }}
            position="relative"
          >
            {/* <FilePreview key={index} {...previewProps} width="100px" height="100px" /> */}
            <img
              src={
                previewProps.srcType == 'file'
                  ? URL.createObjectURL(previewProps?.src)
                  : previewProps.src
              }
              style={{ width: '100%', height: '100%' }}
            />
            <Box
              color="white"
              position="absolute"
              style={{ top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.2)' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box display="flex">
                <FileViewerButton2
                  {...previewProps}
                  srcType="url"
                  src={isNew ? anItem?.file : previewProps.src}
                  size="small"
                  //   style={{ padding: 0 }}
                  document={anItem}
                  isPublic={true}
                >
                  {({ handlePreviewOpen }) => {
                    return (
                      <IconButton onClick={handlePreviewOpen}>
                        {/* <Box display="flex" alignItems="center" height="100%"> */}
                        <VisibilityIcon style={{ color: 'white' }} />
                        {/* </Box> */}
                      </IconButton>
                    );
                  }}
                </FileViewerButton2>
                <IconButton
                  onClick={() => {
                    handleOnRemove(anItem, index);
                  }}
                  size="small"
                >
                  <DeleteIcon style={{ color: 'white' }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      }
    });

    return (
      <Box display="flex">
        {result?.length > 0 && result}
        <Button
          variant="outlined"
          component="label"
          color="primary"
          style={{ width: '100px', height: '100px' }}
          onChange={handleOnSelect}
          //   disabled={disabled}
        >
          Upload
          <input type="file" hidden ref={imageInputRef} multiple accept="image/*" />
        </Button>
      </Box>
    );
  };

  return <Box>{getImagesThumbnails(value)}</Box>;
});

export default ProductImages;
